import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuditService } from '../../services/audit.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  auditForm;
  audits;



  constructor( private formBuilder:FormBuilder, private auditService:AuditService, private toastr:ToastrService,) {
    this.auditForm = this.formBuilder.group({
      user: null,
      names: null,
      surnames: null,
      date: [null,Validators.minLength(4)],
      countryName: null,
      countryYear:null
    })
  }



  ngOnInit() {
    
  }

  onSubmit(auditForm){
    
    console.log('Se ejecuto el submit')

    console.log(auditForm)
    let params:any={}
    if(auditForm.user) params.user=auditForm.user;
    if(auditForm.names) params.name=auditForm.names;
    if(auditForm.surnames) params.surname=auditForm.surnames;
    if(auditForm.date) params.time=auditForm.date+'T00:00:00.000z';
    if(auditForm.countryName) params.countryNameEn=auditForm.countryName;
    if(auditForm.countryYear) params.countryYear=auditForm.countryYear;

    console.log('Se van a imprimir los parametros')
    console.log('Params:',params)

    console.log('Validaciones Date')
    console.log(this.auditForm.get('date').dirty)
    console.log(this.auditForm.get('date').hasError('minlength'))

    if(params.user || params.name || params.surname || params.time || params.countryNameEn || params.countryYear){
      console.log('Se llama al servicio')
      this.auditService.getAudit(params).subscribe(res=>{
        this.audits= res;
        console.log('Res:',res)
        this.audits=this.audits.auditCountry;
        console.log('Audits:',this.audits)
      })
    } else {
      console.log('No se llama al servicio')
    }
   
  }

  resetForm(){
    this.audits=null;
    this.auditForm.reset();
  }

}