import { Component, OnInit } from '@angular/core';
import {CybersecurityInstitucionalService} from '../../services/cybersecurityInstitucionalInfo.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../services/token.service';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'manage-tab-contributors',
  templateUrl: './manage-tab-information-inst.component.html',
  styles: []
})
export class ManageTabInformationInstComponent implements OnInit {

  private institucionalInformation={};
  private allInstitucionalInformation:{};
  private modifying=false;
  private institucionalInfoSelected={}

  ngOnInit() {
    this._tokenService.refreshToken();
    this.getInstitucionalInfo();
    $( "#sortable" ).sortable();
    $( "#sortable" ).disableSelection();
  }

  constructor(
    public _cybersecurityIntitucionalService : CybersecurityInstitucionalService,
    private toastr:ToastrService,
    private _tokenService:TokenService) {}

  save(){
    this._cybersecurityIntitucionalService.saveInstitucionalInformation(this.institucionalInfoSelected).subscribe(result => {});
    this.toastr.success("Informacion actualizada!", 'successfully');
  }

  getInstitucionalInfo(){
    if(JSON.stringify(this.institucionalInformation)==="{}"){
      this._cybersecurityIntitucionalService.getInstitucionalInformation().subscribe(result => {
        this.allInstitucionalInformation= result;
      });
    }
  }

  showInstitucionalInfo(institucionalInfo){
    this.modifying=false;
    this.institucionalInfoSelected = institucionalInfo;
  }

  editInstitucionalInfo(institucionalInfo){
    this.modifying=true;
    this.institucionalInfoSelected = institucionalInfo;
  }



}
