import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { TranslateService } from '../../services/translateService';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: []
})
export class MainComponent implements OnInit {

  title: string = 'Panel de administración';
  identity = {};
  permissions ={};
  public supportedLanguages: any[];
  public token;

  constructor(
    private _userService: UserService,
    private _tokenService: TokenService,
    private _router: Router,
    private _translate: TranslateService
  ) {}

  ngOnInit() {
    
    this._tokenService.refreshToken();
    
    this.identity = this._userService.getIdentity().subscribe(
      (identity) => {
        this.identity= identity,
        this.permissions= identity.permissions
      }     
    );

    this.supportedLanguages = [
    { display: 'English', value: 'en' },
    { display: 'Español', value: 'es' }
    ];
    this.selectLang('es');
  }

  ngAfterViewChecked() {
    this.token = this._tokenService.getToken();
    if (this.token == null) {
      console.log('Token nulo');
      this._router.navigate(['/']);
    }
  }

  logout(){
    console.log('Llego a logout');
    this._tokenService.removeTokenLocalStrage();
    this._router.navigate(['/']);
  }

  changePassword(){
    console.log('Llego a change password')
    this._router.navigate(['/admin-panel/change-password']);

  }

  isSelected(value: string){
    if(value=== this._translate.currentLang) return "#3f89c6";
    else return "black";
  }

  selectLang(lang: string) {
      this._translate.use(lang);
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }

}
