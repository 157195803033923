export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  'salir': 'Logout',
  'modulo_administrativo':'Administrative module of the Cybersecurity Observatory',
  'administrar_contenido':'Manage content',
  'administrar_usuario':'Manage users',
  'crear_nuevo_usuario': 'Create user',
  'gestionar_usuarios': 'Manage users',
  'auditoria': 'Audit',
  'cargar_niv_madurez': 'Load maturity levels',
  'gestionar_niv_madurez': 'Manage maturity levels',
  'ges_acerca_de': 'Manage About',
  'ges_creditos': 'Manage Credits',
  'ges_contribuidores': 'Manage Contributors',
  'ges_info_instituci': 'Manage Institutional Information',
  'ges_info_general_paises': 'Manage General information of countries',

  'nombres':'Name',
  'nombre':'Name',
  'nombres_obligatorios':'The name is required',
  'apellidos':'Last name',
  'apellidos_obligatorios':'The last name is required',
  'correo':'Email',
  'correo_obligatorio':'The email is required',
  'contrasena':'Password',
  'contrasena_obligatorio':'The password is required',
  'buscar_por_nombre':'Search by name',
  'estado':'State',
  'acciones':'Actions',
  'deshabilitar':'Disable',
  'habilitar':'Enable',
  'eliminar':'Delete',

  'pasos':'Steps',
  'seleccionar_archivo':'1. Choose file to upload.',
  'cargar_archivo':'2. Upload file.',
  'verificar_info':'3. Check information to upload.',
  'confirmar_actualizacion':'4. Confirm update.',
  'buscar_archivo':'Choose file',
  'cargar': 'Upload',
  'confirmar': 'Confirm',
  'limpiar': 'Clean',

  'pais_nuevo':'New country',
  'pais_existente':'Country already exists',
  'existen_cambios':'There are changes',
  'informacion_incompleta':'Incomplete information',
  'atributo':'Attribute',
  'valor_existente':'Value already exists',
  'valor_nuevo':'New value',
  'valor':'Value',

  'anio':'Year',
  'detalle':'Detail',
  'confirmo_eliminacion_niveles':'Confirm elimination of maturity levels of ',
  'confirmo_eliminacion':'Confirm elimination',
  'cerrar':'Colse',
  'guardar':'Save',
  'cancelar':'Cancel',
  'resumen_ejecutivo':'executive Summary',
  'descripcion':'Description',
  'contribuidores':'Contributors',
  'informacion_institucional':'Institutional information',
  'editar':'Edit',
  'poblacionTotalDelPais':'Total Population',
  'personasAccesoInternet':'With Internet',
  'personasAccesoCelular':'With Phone',
  'penetracionInternet':'Internet Penetration',



  'user': 'User',
  'names': 'Names',
  'surnames': 'Surnames',
  'date': 'Date',
  'country': 'Country' ,
  'year' : 'Year',
  'data': 'Request'




};
