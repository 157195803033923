import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators'
import { CountryInfo } from '../models/countryInfo';
import { TokenService } from '../services/token.service';

const HEADERS = new HttpHeaders({
    'Content-Type': 'application/json',
})


@Injectable()
export class CountryInfoService {

    public url: string;
    public token;

    countriesInfo: CountryInfo[];

    constructor(
        private http: HttpClient,
        private _tokenService:TokenService
    ) {
        this.url = GLOBAL.url + '/countriesInfo';
    }

    getCountriesInfo(){
        return this.http.get( this.url, { headers: HEADERS } )
            .pipe( map( res => {
                this.countriesInfo = res['countryInfoResult'];
                return this.countriesInfo.sort( (c1, c2): number => {
                    if( c1.nameEs < c2.nameEs ) return -1;
                    if( c1.nameEs > c2.nameEs ) return 1;
                    return 0;
                } );
            } ) );
    }

    updateCountriesInfo(countryInfo){
      let params = JSON.stringify(countryInfo);
      let token = this._tokenService.getToken();
      const HEADERS = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
      return this.http.put( this.url + '/' + countryInfo._id, params, { headers: HEADERS } )
        .pipe( map( res => {
            this.countriesInfo = res['countryInfoResult'];
            return this.countriesInfo;
        } ) );
    }

}
