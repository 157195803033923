import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators'

@Injectable()
export class TokenService {
  public url: string;

  constructor(
    private http: HttpClient,
    private _router: Router
  ) {
    this.url = GLOBAL.url;
  }

  refreshToken() {
    if (this.getToken() == null) {
      this._router.navigate(['/login']);
    } else {
      this.updateToken().subscribe(
        token => {
          localStorage.setItem('token', JSON.stringify(token));
        },
        error => {
          console.log(error.error.message);
          this._router.navigate(['/login']);
        }
      )
    }
  }

  updateToken() {
    let token = this.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.get(this.url + '/update-token', { headers: HEADERS })
      .pipe(map(res => {
        return res['token'];
      }));
  }

  getToken() {
    let token1 = JSON.parse(localStorage.getItem('token'));
    let token = null;
    if (token1 != "undefined") {
      token = token1;
    }
    return token;
  }

  removeTokenLocalStrage() {
    localStorage.removeItem('token');
  }

}
