import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from './global';
import { TokenService } from '../services/token.service';

@Injectable()
export class CybersecurityContentService {

  constructor(
    public http: HttpClient,
    private _tokenService:TokenService) {}

  urlAbout = `${GLOBAL.url}/cybersecurity/about`;
  headers = new HttpHeaders()

  saveAbout(params:object){
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.post(this.urlAbout, params, { headers: HEADERS })
  }

  getAboutInformation(){
    return this.http.get(this.urlAbout, {headers: this.headers})
  }

}
