import { Component, OnInit } from '@angular/core';
import {CybersecurityContentService} from '../../services/cybersecurityContent.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'manage-tab-about',
  templateUrl: './manage-tab-about.component.html',
  styles: []
})
export class ManageTabAboutComponent implements OnInit {
  about={};

  constructor(
    public _cybersecurityService : CybersecurityContentService,
    private toastr:ToastrService,
    private _tokenService:TokenService) { }

  ngOnInit() {
    this._tokenService.refreshToken();
    this.getAbout();
  }

  save(){
    this._cybersecurityService.saveAbout(this.about).subscribe(result => {});
    this.toastr.success("Informacion actualizada!", 'successfully');
  }

  getAbout(){
    if(JSON.stringify(this.about)==="{}"){
      this._cybersecurityService.getAboutInformation().subscribe(result => {
        this.about=result;
      });
    }
  }

  cancel(){
    this._cybersecurityService.getAboutInformation().subscribe(result => {
      this.about=result;
    });
  }

}
