import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styles: []
})
export class UserAddComponent implements OnInit {

    private user:User;

    constructor(
        private _userService:UserService,
        private _tokenService:TokenService,
        private toastr:ToastrService
    ){
        this.user = new User('', '', '', '', '', 'ROLE_USER', true);
    }

    ngOnInit(){
        this._tokenService.refreshToken();
    }

    onSubmit(){
        this._userService.register(this.user).subscribe(
            message => {
                this.user = new User('', '', '', '', '', 'ROLE_USER', true);
                this.toastr.success(message, 'Creación de usuario');
            },
            error =>{
                this.toastr.error("Error al crear usuario")
                console.log(error.error.message);
            }
        )

    }

}
