import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryService } from '../../services/country.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'manage-country-maturity-levels',
  templateUrl: './manage-country-maturity-levels.component.html',
  styleUrls: ['./manage-country-maturity-levels.component.css']

})
export class ManageCountryMaturityLevels implements OnInit {

  public countries:any[];
  public countriesFiltered:any[];
  public busquedaNombre:string="";
  public busquedaAnio:string="";
  public countryToDelete: any = { nameEs:"",year:"" }
  public country:any= {}

  constructor(
    private _countryService:CountryService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _tokenService:TokenService
  ) {}

  ngOnInit() {
    this._tokenService.refreshToken();
    this._countryService.getAllCountries().subscribe(response => {
        this.countries = <any>response;
        this.countriesFiltered= <any>response;
      },
      error => {
        console.log(<any>error);
      }
    )
  }

  onSearchChange() {
        
    this.countriesFiltered = this.countries.filter((country) => {
        return country.nameEs.toLowerCase().startsWith(this.busquedaNombre.toLocaleLowerCase()) && country.year.toLowerCase().includes(this.busquedaAnio.toLocaleLowerCase()) ;
      }
    );    
  }

  showCountry(country_to_show){
    this.country = country_to_show
    console.log(this.country)
  }

  prepareToDeleteCountry(country_to_delete){
    this.countryToDelete = country_to_delete;
  }

  deleteCountry(){
    this._countryService.delteCountry(this.countryToDelete.iso_alpha2, this.countryToDelete.year).subscribe(response => {
         const index: number = this.countriesFiltered.indexOf(this.countryToDelete);
         if (index !== -1) { this.countriesFiltered.splice(index, 1);}
         const index2: number = this.countries.indexOf(this.countryToDelete);
         if (index2 !== -1) { this.countries.splice(index2, 1);}
      }, error => {
        console.log(<any>error);
      }
    )
  }

}
