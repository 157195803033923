import { Component, OnInit } from '@angular/core';
import { CybersecurityCreditService} from '../../services/cyberSecurityCredit.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'manage-tab-contributors',
  templateUrl: './manage-tab-credits.component.html',
  styles: []
})
export class ManageTabCreditsComponent implements OnInit {

  private allCredits:any=[];
  private creditSeleted = {};
  private modifying=false;

  ngOnInit() {
    this._tokenService.refreshToken();
    this.getCredits();
  }

  constructor(
    public _cybersecurityService : CybersecurityCreditService,
    private toastr:ToastrService,
    private _tokenService:TokenService) { }


  getCredits(){
    if(JSON.stringify(this.allCredits)==="[]"){
      this._cybersecurityService.getCredits().subscribe(result => {
        this.allCredits= result;
      });
    }
  }

  save(){
    this._cybersecurityService.saveCredit(this.creditSeleted).subscribe(result => {});
    this.toastr.success("Informacion actualizada!", 'successfully');
  }

  showCredit(credit){
    this.modifying=false;
    this.creditSeleted = credit;
  }

  editCredit(credit){
    this.modifying=true;
    this.creditSeleted = credit;
  }

}
