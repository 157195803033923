import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {


  public token;
  public user: User;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private toastr:ToastrService
  ) {
    // Inicia el usuario
    this.user = new User('', '', '', '', '', 'ROLE_USER', true);
  }

  onSubmit() {
    this._userService.login(this.user).subscribe(
      token => {
        this.token = token;
        localStorage.setItem('token', JSON.stringify(this.token));
        this._router.navigate(['/admin-panel']);
      },
      error => {
        console.log(error.error.message);
        this.toastr.error(error.error.message, 'Login');
      }
    )
  }

}
