import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'manage-users',
  templateUrl: './manage-user.component.html',
  styles: []
})
export class ManageUserComponent implements OnInit {

    private identity = {};
    private users: User[];
    private userToDelete: User = new User("","","","","","",true);

    constructor(
      private _userService: UserService,
      private _tokenService:TokenService) {}

    ngOnInit() {
      this._tokenService.refreshToken();
      this.identity = this._userService.getIdentity();
      this._userService.getAllUsers().subscribe(response => {
          this.users = <User[]>response;
        }, error => {
          console.log(<any>error);
        }
      )
    }

    onSearchChange(value: string) {
      this.users = this._userService.users.filter(
        (user: User) => {
          let fullname = user.name + ' ' + user.surname;
          return fullname.toLowerCase().includes(value.toLocaleLowerCase());
        }
      );
    }

    activateUser(user_to_activate){
      user_to_activate.active = true;
      this._userService.updateUser(user_to_activate).subscribe(response => {
        const index: number = this.users.indexOf(this.userToDelete);
        if (index !== -1) {this.users[index].active = true}
        }, error => {
          console.log(<any>error);
        }
      )
    }

    inactivateUser(user_to_inactivate){
      user_to_inactivate.active = false;
      this._userService.updateUser(user_to_inactivate).subscribe(response => {
        const index: number = this.users.indexOf(this.userToDelete);
        if (index !== -1) {this.users[index].active = false}
        }, error => {
          console.log(<any>error);
        }
      )
    }

    prepareToDeleteUser(user_to_delete){
      this.userToDelete = user_to_delete;
    }

    generateQR(user){
      console.log(user)
      this._userService.regenerateQR(user).subscribe(response => {
        // Respuesta
      }, error => {
        console.log(<any>error);
      })
    }

    deleteUser(){
      this._userService.deleteUser(this.userToDelete).subscribe(response => {
           const index: number = this.users.indexOf(this.userToDelete);
           if (index !== -1) { this.users.splice(index, 1);}
        }, error => {
          console.log(<any>error);
        }
      )
    }
}
