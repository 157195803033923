import { Component, OnInit } from '@angular/core';
import {CybersecurityContributorService} from '../../services/cyberSecurityContributor.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'manage-tab-contributors',
  templateUrl: './manage-tab-contributors.component.html',
  styles: []
})
export class ManageTabContributorsComponent implements OnInit {

  private contributors:any=[];
  private allContributors:any=[];
  private contributorSeleted = {};
  private modifying=false;

  ngOnInit() {
    this._tokenService.refreshToken();
    this.getcontributors();
  }

  constructor(
    public _cybersecurityService : CybersecurityContributorService,
    private toastr:ToastrService,
    private _tokenService:TokenService) { }

    getcontributors(){
      if(JSON.stringify(this.allContributors)==="[]"){
        this._cybersecurityService.getContributors().subscribe(result => {
          this.allContributors= result;
          this.contributors= result;
        });
      }
    }

    save(){
      this._cybersecurityService.saveContributor(this.contributorSeleted).subscribe(result => {});
      this.toastr.success("Informacion actualizada!", 'successfully');
    }

    showContributor(contributor){
      this.modifying=false;
      this.contributorSeleted = contributor;
    }

    editContributor(contributor){
      this.modifying=true;
      this.contributorSeleted = contributor;
    }

    onSearchChange(value: string) {
      this.allContributors = this.contributors.filter(
        (contrib) => {
          return contrib.contributorEs.toLowerCase().startsWith(value.toLocaleLowerCase());
        }
      );
    }

}
