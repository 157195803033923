import { Component, OnInit } from '@angular/core';
import { CountryInfoService } from '../../services/countryInfo.service';
import { CountryInfo } from '../../models/countryInfo';
import { TokenService } from '../../services/token.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'manage-country-information',
  templateUrl: './manage-country-information.component.html',
  styles: []
})
export class ManageCountryInformationComponent implements OnInit {

  private countriesInfo: CountryInfo[];
  countrySelected: CountryInfo= {
      "_id": "",
      "nameEs": "",
      "nameEn": "",
      "iso_alpha2": "",
      "totalPopulation": "",
      "peopleWithInternet": "",
      "peopleWithPhones": "",
      "internetPenetration": "",
      "infoEs": [],
      "infoEn": [],
  }
  private modifying=false;

  constructor(
    private _countryInfoService: CountryInfoService,
    private toastr:ToastrService,
    private _tokenService:TokenService
  ) {}

  ngOnInit() {
    this._tokenService.refreshToken();
    this._countryInfoService.getCountriesInfo().subscribe(
      response => {
        this.countriesInfo = <CountryInfo[]>response;
      },
      error => {
        console.log(<any>error);
      }
    )
  }

  onSearchChange(value: string) {
    this.countriesInfo = this._countryInfoService.countriesInfo.filter(
      (countryInfo: CountryInfo) => {
        return countryInfo.nameEs.toLowerCase().startsWith(value.toLocaleLowerCase());
      }
    );
  }

  showCountry(country : CountryInfo){
    this.modifying=false;
    this.countrySelected = country;
  }

  editCountry(country : CountryInfo){
    this.modifying=true;
    this.countrySelected = country;
  }

  updateCountryInfo(){
    this._countryInfoService.updateCountriesInfo(this.countrySelected).subscribe(response => {
        this.toastr.success("Informacion actualizada!", 'successfully');
        return response
      }, error => {
        console.log(<any>error);
      }
    )
  }

}
