export class AttributesState {

    policyAndStrategy: PolicyAndStrategy;
    cyberCultureAndSociety: CyberCultureAndSociety;
    educationTraining: Education;
    legalAndRegulatoryFrameworks: LegalAndRegulatoryFrameworks;
    technologies: Technologies;


    constructor() {

        this.policyAndStrategy= new PolicyAndStrategy();
        this.cyberCultureAndSociety = new CyberCultureAndSociety();
        this.educationTraining = new Education();
        this.legalAndRegulatoryFrameworks = new LegalAndRegulatoryFrameworks();
        this.technologies = new Technologies();
    }
}


class NationalCybersecurityStrategy {
    strategyDevelopment: string;
    organization: string;
    content: string;

    constructor() {
        this.strategyDevelopment='none';
        this.organization= 'none';
        this.content= 'none';
    }
}


class IncidentResponse {
    identificationOfIncidents: string;
    organization: string;
    coordination: string;
    modeOfOperation: string;

    constructor(){
        this.identificationOfIncidents='none';
        this.organization='none';
        this.coordination='none';
        this.modeOfOperation='none';
    }
}

class CriticalInfrastructureProtection {
    identificationOfIncidents:string;
    organization:string;
    riskManagementAndResponse:string;

    constructor(){
        this.identificationOfIncidents='none';
        this.organization='none';
        this.riskManagementAndResponse='none';
    }
}

class CrisisManagement {
    crisisManagement:string;

    constructor(){
        this.crisisManagement='none';
    }
}

class CrisisDefense {
    strategy:string;
    organization:string;
    coordination:string;

    constructor(){
        this.strategy='none';
        this.organization='none';
        this.coordination='none';
    }
}

class CommunicationsRedundancy  {
    communicationsRedundancy: string;
    constructor(){
        this.communicationsRedundancy='none'
    }
    
}

class PolicyAndStrategy {
    
    nationalCybersecurityStrategy: NationalCybersecurityStrategy;
    incidentResponse: IncidentResponse;
    criticalInfrastructureProtection:CriticalInfrastructureProtection;
    crisisManagement:CrisisManagement;
    crisisDefense:CrisisDefense;
    communicationsRedundancy:CommunicationsRedundancy

    constructor(){
        this.nationalCybersecurityStrategy=new NationalCybersecurityStrategy();
        this.incidentResponse= new IncidentResponse();
        this.criticalInfrastructureProtection= new CriticalInfrastructureProtection();
        this.crisisManagement= new CrisisManagement();
        this.crisisDefense= new CrisisDefense();
        this.communicationsRedundancy= new CommunicationsRedundancy();
    }
}

// Culture And Society

class CyberSecurityMindSet {
    government: string;
    privateSector: string;
    users: string;

    constructor(){
        this.government='none';
        this.privateSector='none';
        this.users='none';
    }
}


class TrustConfidenceOnInternet {
    userTrustOnInternet:string;
    userTrustEGovermmentOnServices:string;
    userTrustEcommerceService:string;

    constructor(){
        this.userTrustOnInternet='none';
        this.userTrustEGovermmentOnServices='none';
        this.userTrustEcommerceService='none';
    }
}

class UserUnderstandingPersonalInformation {
    userUnderstandingPersonalInformation:string;

    constructor(){
        this.userUnderstandingPersonalInformation='none';
    }
}


class ReportingMechanisms {
    reportingMechanisms:string;

    constructor(){
        this.reportingMechanisms='none';
    }
}

class MediaAndSocialMedia {
    mediaAndSocialMedia:string;

    constructor(){
        this.mediaAndSocialMedia = 'none';
    }
}

class CyberCultureAndSociety {
    cyberSecurityMindSet: CyberSecurityMindSet;
    trustConfidenceOnInternet: TrustConfidenceOnInternet;
    userUnderstandingPersonalInformation: UserUnderstandingPersonalInformation;
    reportingMechanisms: ReportingMechanisms;
    mediaAndSocialMedia: MediaAndSocialMedia
    
    constructor(){
        this.cyberSecurityMindSet= new CyberSecurityMindSet();
        this.trustConfidenceOnInternet = new TrustConfidenceOnInternet();
        this.userUnderstandingPersonalInformation= new UserUnderstandingPersonalInformation();
        this.reportingMechanisms=new ReportingMechanisms();
        this.mediaAndSocialMedia= new MediaAndSocialMedia();
    }
}



// Education

class AwarenessRaising {
    awarenessRaisingProgrammes: string;
    executiveAwarenessRaising: string;

    constructor(){
        this.awarenessRaisingProgrammes='none';
        this.executiveAwarenessRaising='none';
    }
}

class FrameworkForEducation {
    provision: string;
    administration: string;

    constructor(){
        this.provision='none';
        this.administration='none';
    }
}

class FrameworkForProfessionalTraining {
    provision: string;
    uptake: string;

    constructor(){
        this.provision='none';
        this.uptake='none';
    }
}


class Education {
    awarenessRaising: AwarenessRaising;
    frameworkForEducation: FrameworkForEducation;
    frameworkForProfessionalTraining: FrameworkForProfessionalTraining;

    constructor(){
        this.awarenessRaising=new AwarenessRaising();
        this.frameworkForEducation = new FrameworkForEducation();
        this.frameworkForProfessionalTraining = new FrameworkForProfessionalTraining();
    }
}

// Legal frameworks

class LegalFrameworks {
    legislativeFrameworks: string;
    humanRightsOnline: string;
    dataProtectionLegislation: string;
    childProtectionOnline: string;
    consumerProtectionLegislation: string;
    intellectualPropertyLegislation: string;
    substantiveCybercrimeLegislation: string;
    proceduralCybercrimeLegislation: string;

    constructor(){
        this.legislativeFrameworks='none';
        this.humanRightsOnline='none';
        this.dataProtectionLegislation='none';
        this.childProtectionOnline='none';
        this.consumerProtectionLegislation='none';
        this.intellectualPropertyLegislation='none';
        this.substantiveCybercrimeLegislation='none';
        this.proceduralCybercrimeLegislation='none';
    }
}

class CriminalJusticeSystem {
    lawEnforcement: string;
    prosecution: string;
    courts: string;

    constructor(){
        this.lawEnforcement='none';
        this.prosecution='none';
        this.courts='none';
    }
}

class FormalAndInformalCombatCybercrime {
    formalCooperation: string;
    informalCooperation: string;

    constructor(){
        this.formalCooperation='none';
        this.informalCooperation='none';
    }
}

class LegalAndRegulatoryFrameworks {
    legalFrameworks: LegalFrameworks;
    criminalJusticeSystem: CriminalJusticeSystem;
    formalAndInformalCombatCybercrime: FormalAndInformalCombatCybercrime;

    constructor(){
        this.legalFrameworks=new LegalFrameworks();
        this.criminalJusticeSystem= new CriminalJusticeSystem();
        this.formalAndInformalCombatCybercrime = new FormalAndInformalCombatCybercrime();
    }
}

// Technologies




class AdherenceToStandards {
    securityStandardsICT: string;
    standardsProcurement: string;
    standardsSoftwareDevelopment: string;

    constructor(){
        this.securityStandardsICT='none';
        this.standardsProcurement='none';
        this.standardsSoftwareDevelopment='none';
    }
}

class InternetInfrastructureResilience {
    internetInfrastructureResilience:string;
    constructor(){
        this.internetInfrastructureResilience='none';
    }
}

class SoftwareQuality {
    softwareQuality: string;

    constructor(){
        this.softwareQuality='none';
    }
}

class TechnicalSecurityControls {
    technicalSecurityControls: string;

    constructor(){
        this.technicalSecurityControls='none';
    }
}


class CryptographicControls {
    cryptographicControls: string;

    constructor(){
        this.cryptographicControls='none';
    }
}


class CyberSecurityMarketplace {
    cyberSecurityTechnologies:string;
    cybercrimeInsurance:string;
    constructor(){
        this.cyberSecurityTechnologies='none';
        this.cybercrimeInsurance='none';
    }
}

class ResponsibleDisclosure {
    responsibleDisclosure:string;
    constructor(){
        this.responsibleDisclosure='none';
    }
}


class Technologies {
    adherenceToStandards: AdherenceToStandards;
    internetInfrastructureResilience:InternetInfrastructureResilience;
    softwareQuality: SoftwareQuality;
    technicalSecurityControls: TechnicalSecurityControls;
    cryptographicControls: CryptographicControls;
    cyberSecurityMarketplace: CyberSecurityMarketplace;
    responsibleDisclosure: ResponsibleDisclosure;
    
    constructor(){
        this.adherenceToStandards= new AdherenceToStandards();
        this.internetInfrastructureResilience= new InternetInfrastructureResilience();
        this.softwareQuality=new SoftwareQuality();
        this.technicalSecurityControls= new TechnicalSecurityControls()
        this.cryptographicControls= new CryptographicControls();
        this.cyberSecurityMarketplace= new CyberSecurityMarketplace();
        this.responsibleDisclosure= new ResponsibleDisclosure();

    }
}