import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators'
import { User } from '../models/user';
import { TokenService } from '../services/token.service';

@Injectable()
export class UserService {

  public url: string;
  public identity;
  public token;
  public users: User[];

  constructor(
    private http: HttpClient,
    private _tokenService:TokenService
  ) {
    this.url = GLOBAL.url;
  }

  register(user_to_register) {
    let params = JSON.stringify(user_to_register);
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    console.log('Params:',params)
    return this.http.post(this.url + '/register', params, { headers: HEADERS })
      .pipe(map(res => {
        return res['message'];
      }));
  }

  login(user_to_login) {
    let params = JSON.stringify(user_to_login);
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    return this.http.post(this.url + '/login', params, { headers: HEADERS })
      .pipe(map(res => {
        this.identity = res['identity'];
        console.log('Identity:',this.identity)
        return res['token'];
      }));
  }

  getAllUsers(){
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.get( this.url + '/users', { headers: HEADERS } )
      .pipe( map( res => {
        this.users = res['userResult'];
        return this.users;
      } ) );
  }

  updateUser(user){
    let params = JSON.stringify(user);
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.put( this.url + '/update-user/' + user._id, params, { headers: HEADERS } )
      .pipe( map( res => {
        this.users = res['userResult'];
        return this.users;
      } ) );
  }

  deleteUser(user){
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.delete( this.url + '/user/' + user._id , { headers: HEADERS } )
      .pipe( map( res => {
        this.users = res['userResult'];
        return this.users;
      } ) );
  }

  regenerateQR(user){
    let params = JSON.stringify(user);
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.post(this.url + '/regenerate-qr', params, { headers: HEADERS })
      .pipe(map(response => {
        console.log(response)
        return response;
      }));
  }

  
  getIdentity(){
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.get( this.url + '/identity/', { headers: HEADERS } )
      .pipe( map( res => {
        this.identity = res['identity'];
        return this.identity;
      } ) );
  }

  changePassword(passwords){
    let payload = JSON.stringify(passwords);
    let token = this._tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    return this.http.post(this.url + '/change-password', payload, { headers: HEADERS })
      .pipe(map(response => {
        return response;
      }));
  }

  getPermissions(){
    return this.identity.permissions;
  }



}
