import { Component, OnInit } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { GLOBAL } from '../../services/global';
import { CountryService } from '../../services/country.service';
import { TokenService } from '../../services/token.service';
import { AttributesState } from '../../models/attributesState'
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2';

const URL = `${GLOBAL.url}/uploadFile`;

@Component({
  selector: 'app-country-upload',
  templateUrl: './country-upload.component.html'
})
export class CountryUploadComponent implements OnInit {

  title: string;
  fileName: string;
  data = {};
  dataBefore = [];
  dataView = {};
  isDataComplete:boolean;
  thereAreChanges:boolean;
  isAvailableLoad:boolean;

  uploader: FileUploader;

  mySubscription:any;

  constructor(
    private _countryService: CountryService,
    private _router:Router,
    private _tokenService:TokenService
  ) {

    this._router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
      };
     
    this.mySubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
          this._router.navigated = false;
        }
      });
  }

  ngOnInit() {
    this._tokenService.refreshToken();
    this.uploader = new FileUploader({ url: URL });
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status);

    this.data = {};
    this.dataBefore = [];
    this.dataView = {};
    this.isDataComplete = true;
    this.thereAreChanges = false;
    this.isAvailableLoad = true; 
  }

  loadFile() {
    this.uploader.uploadAll();
  }

  onChange() {
    console.log('Se ejecuto el onchange');
    if (this.uploader.queue.length > 1) {
      this.uploader.queue[0].remove();
    }
    this.fileName = this.uploader.queue[0].file.name;
  }

  ngOnDestroy() {
    if (this.mySubscription)
     this.mySubscription.unsubscribe();
  }

  cleanQueue() {
    this._router.navigate(['/admin-panel/cargar-archivo']);
  }

  onSuccessItem(item: FileItem, response: string, status: number): any {
    this.data = JSON.parse(response);
    this.dataView = JSON.parse(response); //success server response
    this.getInfoCountries();
  }

  getInfoCountries() {
    for (let country of this.dataView['carga']) {

      this._countryService.getCountryByIsoAndYear(country.iso_alpha2, country.year).subscribe(
        res => {
          if (res.length > 0) {
            this.isDataComplete = true;
            this.thereAreChanges = false;
            country.atributesBefore = res[0].attributes;
            country.isNew = false;
            country.attributesState = this.getAttributesState(country.atributesBefore, country.attributes);
            country.isDataComplete = this.isDataComplete;
            country.thereAreChanges = this.thereAreChanges;
          } else {
            this.isDataComplete = true;
            country.isNew = true;
            country.attributesState = this.getAttributesStateNew(country.attributes);
            country.isDataComplete = this.isDataComplete;
          }
        }, err => {
          console.log(err);
        }
      )
    }
  }

  getAttributesState(attributesBefore, attributesAfter): AttributesState {
    let attributesState: AttributesState = new AttributesState();

    // Policy And Strategy
    attributesState.policyAndStrategy.nationalCybersecurityStrategy.strategyDevelopment = this.getState(attributesBefore.policyAndStrategy.nationalCybersecurityStrategy.strategyDevelopment, attributesAfter.policyAndStrategy.nationalCybersecurityStrategy.strategyDevelopment);
    attributesState.policyAndStrategy.nationalCybersecurityStrategy.organization = this.getState(attributesBefore.policyAndStrategy.nationalCybersecurityStrategy.organization, attributesAfter.policyAndStrategy.nationalCybersecurityStrategy.organization);
    attributesState.policyAndStrategy.nationalCybersecurityStrategy.content = this.getState(attributesBefore.policyAndStrategy.nationalCybersecurityStrategy.content, attributesAfter.policyAndStrategy.nationalCybersecurityStrategy.content);

    attributesState.policyAndStrategy.incidentResponse.identificationOfIncidents = this.getState(attributesBefore.policyAndStrategy.incidentResponse.identificationOfIncidents, attributesAfter.policyAndStrategy.incidentResponse.identificationOfIncidents);
    attributesState.policyAndStrategy.incidentResponse.organization = this.getState(attributesBefore.policyAndStrategy.incidentResponse.organization, attributesAfter.policyAndStrategy.incidentResponse.organization);
    attributesState.policyAndStrategy.incidentResponse.coordination = this.getState(attributesBefore.policyAndStrategy.incidentResponse.coordination, attributesAfter.policyAndStrategy.incidentResponse.coordination);
    attributesState.policyAndStrategy.incidentResponse.modeOfOperation = this.getState(attributesBefore.policyAndStrategy.incidentResponse.modeOfOperation, attributesAfter.policyAndStrategy.incidentResponse.modeOfOperation);

    attributesState.policyAndStrategy.criticalInfrastructureProtection.identificationOfIncidents = this.getState(attributesBefore.policyAndStrategy.criticalInfrastructureProtection.identificationOfIncidents, attributesAfter.policyAndStrategy.criticalInfrastructureProtection.identificationOfIncidents);
    attributesState.policyAndStrategy.criticalInfrastructureProtection.organization = this.getState(attributesBefore.policyAndStrategy.criticalInfrastructureProtection.organization, attributesAfter.policyAndStrategy.criticalInfrastructureProtection.organization);
    attributesState.policyAndStrategy.criticalInfrastructureProtection.riskManagementAndResponse = this.getState(attributesBefore.policyAndStrategy.criticalInfrastructureProtection.riskManagementAndResponse, attributesAfter.policyAndStrategy.criticalInfrastructureProtection.riskManagementAndResponse);
    
    attributesState.policyAndStrategy.crisisManagement.crisisManagement = this.getState(attributesBefore.policyAndStrategy.crisisManagement.crisisManagement, attributesAfter.policyAndStrategy.crisisManagement.crisisManagement);
   
    attributesState.policyAndStrategy.crisisDefense.strategy = this.getState(attributesBefore.policyAndStrategy.crisisDefense.strategy, attributesAfter.policyAndStrategy.crisisDefense.strategy);
    attributesState.policyAndStrategy.crisisDefense.organization = this.getState(attributesBefore.policyAndStrategy.crisisDefense.organization, attributesAfter.policyAndStrategy.crisisDefense.organization);
    attributesState.policyAndStrategy.crisisDefense.coordination = this.getState(attributesBefore.policyAndStrategy.crisisDefense.coordination, attributesAfter.policyAndStrategy.crisisDefense.coordination);
   
    attributesState.policyAndStrategy.communicationsRedundancy.communicationsRedundancy = this.getState(attributesBefore.policyAndStrategy.communicationsRedundancy.communicationsRedundancy, attributesAfter.policyAndStrategy.communicationsRedundancy.communicationsRedundancy);
   

    // Culture and society
    attributesState.cyberCultureAndSociety.cyberSecurityMindSet.government = this.getState(attributesBefore.cyberCultureAndSociety.cyberSecurityMindSet.government, attributesAfter.cyberCultureAndSociety.cyberSecurityMindSet.government);
    attributesState.cyberCultureAndSociety.cyberSecurityMindSet.privateSector = this.getState(attributesBefore.cyberCultureAndSociety.cyberSecurityMindSet.privateSector, attributesAfter.cyberCultureAndSociety.cyberSecurityMindSet.privateSector);
    attributesState.cyberCultureAndSociety.cyberSecurityMindSet.users = this.getState(attributesBefore.cyberCultureAndSociety.cyberSecurityMindSet.users, attributesAfter.cyberCultureAndSociety.cyberSecurityMindSet.users);

    attributesState.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustOnInternet = this.getState(attributesBefore.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustOnInternet, attributesAfter.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustOnInternet);
    attributesState.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEGovermmentOnServices = this.getState(attributesBefore.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEGovermmentOnServices, attributesAfter.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEGovermmentOnServices);
    attributesState.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEcommerceService = this.getState(attributesBefore.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEcommerceService, attributesAfter.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEcommerceService);

    attributesState.cyberCultureAndSociety.userUnderstandingPersonalInformation.userUnderstandingPersonalInformation = this.getState(attributesBefore.cyberCultureAndSociety.userUnderstandingPersonalInformation.userUnderstandingPersonalInformation, attributesAfter.cyberCultureAndSociety.userUnderstandingPersonalInformation.userUnderstandingPersonalInformation);

    attributesState.cyberCultureAndSociety.reportingMechanisms.reportingMechanisms = this.getState(attributesBefore.cyberCultureAndSociety.reportingMechanisms.reportingMechanisms, attributesAfter.cyberCultureAndSociety.reportingMechanisms.reportingMechanisms);

    attributesState.cyberCultureAndSociety.mediaAndSocialMedia.mediaAndSocialMedia = this.getState(attributesBefore.cyberCultureAndSociety.mediaAndSocialMedia.mediaAndSocialMedia, attributesAfter.cyberCultureAndSociety.mediaAndSocialMedia.mediaAndSocialMedia);

    // Education
    attributesState.educationTraining.awarenessRaising.awarenessRaisingProgrammes = this.getState(attributesBefore.educationTraining.awarenessRaising.awarenessRaisingProgrammes, attributesAfter.educationTraining.awarenessRaising.awarenessRaisingProgrammes);
    attributesState.educationTraining.awarenessRaising.executiveAwarenessRaising = this.getState(attributesBefore.educationTraining.awarenessRaising.executiveAwarenessRaising, attributesAfter.educationTraining.awarenessRaising.executiveAwarenessRaising);

    attributesState.educationTraining.frameworkForEducation.provision = this.getState(attributesBefore.educationTraining.frameworkForEducation.provision, attributesAfter.educationTraining.frameworkForEducation.provision);
    attributesState.educationTraining.frameworkForEducation.administration = this.getState(attributesBefore.educationTraining.frameworkForEducation.administration, attributesAfter.educationTraining.frameworkForEducation.administration);

    attributesState.educationTraining.frameworkForProfessionalTraining.provision = this.getState(attributesBefore.educationTraining.frameworkForProfessionalTraining.provision, attributesAfter.educationTraining.frameworkForProfessionalTraining.provision);
    attributesState.educationTraining.frameworkForProfessionalTraining.uptake = this.getState(attributesBefore.educationTraining.frameworkForProfessionalTraining.uptake, attributesAfter.educationTraining.frameworkForProfessionalTraining.uptake);

    // Legal Frameworks
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.legislativeFrameworks = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.legislativeFrameworks, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.legislativeFrameworks);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.humanRightsOnline = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.humanRightsOnline, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.humanRightsOnline);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.dataProtectionLegislation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.dataProtectionLegislation, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.dataProtectionLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.childProtectionOnline = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.childProtectionOnline, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.childProtectionOnline);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.consumerProtectionLegislation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.consumerProtectionLegislation, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.consumerProtectionLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.intellectualPropertyLegislation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.intellectualPropertyLegislation, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.intellectualPropertyLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.substantiveCybercrimeLegislation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.substantiveCybercrimeLegislation, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.substantiveCybercrimeLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.proceduralCybercrimeLegislation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.legalFrameworks.proceduralCybercrimeLegislation, attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.proceduralCybercrimeLegislation);

    attributesState.legalAndRegulatoryFrameworks.criminalJusticeSystem.lawEnforcement = this.getState(attributesBefore.legalAndRegulatoryFrameworks.criminalJusticeSystem.lawEnforcement, attributesAfter.legalAndRegulatoryFrameworks.criminalJusticeSystem.lawEnforcement);
    attributesState.legalAndRegulatoryFrameworks.criminalJusticeSystem.prosecution = this.getState(attributesBefore.legalAndRegulatoryFrameworks.criminalJusticeSystem.prosecution, attributesAfter.legalAndRegulatoryFrameworks.criminalJusticeSystem.prosecution);
    attributesState.legalAndRegulatoryFrameworks.criminalJusticeSystem.courts = this.getState(attributesBefore.legalAndRegulatoryFrameworks.criminalJusticeSystem.courts, attributesAfter.legalAndRegulatoryFrameworks.criminalJusticeSystem.courts);

    attributesState.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.formalCooperation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.formalCooperation, attributesAfter.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.formalCooperation);
    attributesState.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.informalCooperation = this.getState(attributesBefore.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.informalCooperation, attributesAfter.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.informalCooperation);



    // Technologies
    attributesState.technologies.adherenceToStandards.securityStandardsICT = this.getState(attributesBefore.technologies.adherenceToStandards.securityStandardsICT, attributesAfter.technologies.adherenceToStandards.securityStandardsICT);
    attributesState.technologies.adherenceToStandards.standardsProcurement = this.getState(attributesBefore.technologies.adherenceToStandards.standardsProcurement, attributesAfter.technologies.adherenceToStandards.standardsProcurement);
    attributesState.technologies.adherenceToStandards.standardsSoftwareDevelopment = this.getState(attributesBefore.technologies.adherenceToStandards.standardsSoftwareDevelopment, attributesAfter.technologies.adherenceToStandards.standardsSoftwareDevelopment);

    attributesState.technologies.internetInfrastructureResilience.internetInfrastructureResilience = this.getState(attributesBefore.technologies.internetInfrastructureResilience.internetInfrastructureResilience, attributesAfter.technologies.internetInfrastructureResilience.internetInfrastructureResilience);
    attributesState.technologies.softwareQuality.softwareQuality = this.getState(attributesBefore.technologies.softwareQuality.softwareQuality, attributesAfter.technologies.softwareQuality.softwareQuality);

    attributesState.technologies.technicalSecurityControls.technicalSecurityControls = this.getState(attributesBefore.technologies.technicalSecurityControls.technicalSecurityControls, attributesAfter.technologies.technicalSecurityControls.technicalSecurityControls);

    attributesState.technologies.cryptographicControls.cryptographicControls = this.getState(attributesBefore.technologies.cryptographicControls.cryptographicControls, attributesAfter.technologies.cryptographicControls.cryptographicControls);

    attributesState.technologies.cyberSecurityMarketplace.cyberSecurityTechnologies = this.getState(attributesBefore.technologies.cyberSecurityMarketplace.cyberSecurityTechnologies, attributesAfter.technologies.cyberSecurityMarketplace.cyberSecurityTechnologies);
    attributesState.technologies.cyberSecurityMarketplace.cybercrimeInsurance = this.getState(attributesBefore.technologies.cyberSecurityMarketplace.cybercrimeInsurance, attributesAfter.technologies.cyberSecurityMarketplace.cybercrimeInsurance);


    attributesState.technologies.responsibleDisclosure.responsibleDisclosure = this.getState(attributesBefore.technologies.responsibleDisclosure.responsibleDisclosure, attributesAfter.technologies.responsibleDisclosure.responsibleDisclosure);

    return attributesState;
  }

  getAttributesStateNew(attributesAfter): AttributesState {
    let attributesState: AttributesState = new AttributesState();

    // Policy And Strategy
    attributesState.policyAndStrategy.nationalCybersecurityStrategy.strategyDevelopment = this.getStateNew(attributesAfter.policyAndStrategy.nationalCybersecurityStrategy.strategyDevelopment);
    attributesState.policyAndStrategy.nationalCybersecurityStrategy.organization = this.getStateNew(attributesAfter.policyAndStrategy.nationalCybersecurityStrategy.organization);
    attributesState.policyAndStrategy.nationalCybersecurityStrategy.content = this.getStateNew(attributesAfter.policyAndStrategy.nationalCybersecurityStrategy.content);

    attributesState.policyAndStrategy.incidentResponse.identificationOfIncidents = this.getStateNew(attributesAfter.policyAndStrategy.incidentResponse.identificationOfIncidents);
    attributesState.policyAndStrategy.incidentResponse.organization = this.getStateNew(attributesAfter.policyAndStrategy.incidentResponse.organization);
    attributesState.policyAndStrategy.incidentResponse.coordination = this.getStateNew(attributesAfter.policyAndStrategy.incidentResponse.coordination);
    attributesState.policyAndStrategy.incidentResponse.modeOfOperation = this.getStateNew(attributesAfter.policyAndStrategy.incidentResponse.modeOfOperation);

    attributesState.policyAndStrategy.criticalInfrastructureProtection.identificationOfIncidents = this.getStateNew(attributesAfter.policyAndStrategy.criticalInfrastructureProtection.identificationOfIncidents);
    attributesState.policyAndStrategy.criticalInfrastructureProtection.organization = this.getStateNew(attributesAfter.policyAndStrategy.criticalInfrastructureProtection.organization);
    attributesState.policyAndStrategy.criticalInfrastructureProtection.riskManagementAndResponse = this.getStateNew(attributesAfter.policyAndStrategy.criticalInfrastructureProtection.riskManagementAndResponse);
    
    attributesState.policyAndStrategy.crisisManagement.crisisManagement = this.getStateNew(attributesAfter.policyAndStrategy.crisisManagement.crisisManagement);
   
    attributesState.policyAndStrategy.crisisDefense.strategy = this.getStateNew(attributesAfter.policyAndStrategy.crisisDefense.strategy);
    attributesState.policyAndStrategy.crisisDefense.organization = this.getStateNew(attributesAfter.policyAndStrategy.crisisDefense.organization);
    attributesState.policyAndStrategy.crisisDefense.coordination = this.getStateNew(attributesAfter.policyAndStrategy.crisisDefense.coordination);
   
    attributesState.policyAndStrategy.communicationsRedundancy.communicationsRedundancy = this.getStateNew(attributesAfter.policyAndStrategy.communicationsRedundancy.communicationsRedundancy);
   

    // Culture and society
    attributesState.cyberCultureAndSociety.cyberSecurityMindSet.government = this.getStateNew(attributesAfter.cyberCultureAndSociety.cyberSecurityMindSet.government);
    attributesState.cyberCultureAndSociety.cyberSecurityMindSet.privateSector = this.getStateNew(attributesAfter.cyberCultureAndSociety.cyberSecurityMindSet.privateSector);
    attributesState.cyberCultureAndSociety.cyberSecurityMindSet.users = this.getStateNew(attributesAfter.cyberCultureAndSociety.cyberSecurityMindSet.users);

    attributesState.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustOnInternet = this.getStateNew(attributesAfter.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustOnInternet);
    attributesState.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEGovermmentOnServices = this.getStateNew(attributesAfter.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEGovermmentOnServices);
    attributesState.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEcommerceService = this.getStateNew(attributesAfter.cyberCultureAndSociety.trustConfidenceOnInternet.userTrustEcommerceService);

    attributesState.cyberCultureAndSociety.userUnderstandingPersonalInformation.userUnderstandingPersonalInformation = this.getStateNew(attributesAfter.cyberCultureAndSociety.userUnderstandingPersonalInformation.userUnderstandingPersonalInformation);

    attributesState.cyberCultureAndSociety.reportingMechanisms.reportingMechanisms = this.getStateNew(attributesAfter.cyberCultureAndSociety.reportingMechanisms.reportingMechanisms);

    attributesState.cyberCultureAndSociety.mediaAndSocialMedia.mediaAndSocialMedia = this.getStateNew(attributesAfter.cyberCultureAndSociety.mediaAndSocialMedia.mediaAndSocialMedia);

    // Education
    attributesState.educationTraining.awarenessRaising.awarenessRaisingProgrammes = this.getStateNew(attributesAfter.educationTraining.awarenessRaising.awarenessRaisingProgrammes);
    attributesState.educationTraining.awarenessRaising.executiveAwarenessRaising = this.getStateNew(attributesAfter.educationTraining.awarenessRaising.executiveAwarenessRaising);

    attributesState.educationTraining.frameworkForEducation.provision = this.getStateNew(attributesAfter.educationTraining.frameworkForEducation.provision);
    attributesState.educationTraining.frameworkForEducation.administration = this.getStateNew(attributesAfter.educationTraining.frameworkForEducation.administration);

    attributesState.educationTraining.frameworkForProfessionalTraining.provision = this.getStateNew(attributesAfter.educationTraining.frameworkForProfessionalTraining.provision);
    attributesState.educationTraining.frameworkForProfessionalTraining.uptake = this.getStateNew(attributesAfter.educationTraining.frameworkForProfessionalTraining.uptake);

    // Legal Frameworks
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.legislativeFrameworks = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.legislativeFrameworks);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.humanRightsOnline = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.humanRightsOnline);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.dataProtectionLegislation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.dataProtectionLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.childProtectionOnline = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.childProtectionOnline);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.consumerProtectionLegislation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.consumerProtectionLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.intellectualPropertyLegislation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.intellectualPropertyLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.substantiveCybercrimeLegislation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.substantiveCybercrimeLegislation);
    attributesState.legalAndRegulatoryFrameworks.legalFrameworks.proceduralCybercrimeLegislation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.legalFrameworks.proceduralCybercrimeLegislation);

    attributesState.legalAndRegulatoryFrameworks.criminalJusticeSystem.lawEnforcement = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.criminalJusticeSystem.lawEnforcement);
    attributesState.legalAndRegulatoryFrameworks.criminalJusticeSystem.prosecution = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.criminalJusticeSystem.prosecution);
    attributesState.legalAndRegulatoryFrameworks.criminalJusticeSystem.courts = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.criminalJusticeSystem.courts);

    attributesState.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.formalCooperation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.formalCooperation);
    attributesState.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.informalCooperation = this.getStateNew(attributesAfter.legalAndRegulatoryFrameworks.formalAndInformalCombatCybercrime.informalCooperation);



    // Technologies
    attributesState.technologies.adherenceToStandards.securityStandardsICT = this.getStateNew(attributesAfter.technologies.adherenceToStandards.securityStandardsICT);
    attributesState.technologies.adherenceToStandards.standardsProcurement = this.getStateNew(attributesAfter.technologies.adherenceToStandards.standardsProcurement);
    attributesState.technologies.adherenceToStandards.standardsSoftwareDevelopment = this.getStateNew(attributesAfter.technologies.adherenceToStandards.standardsSoftwareDevelopment);

    attributesState.technologies.internetInfrastructureResilience.internetInfrastructureResilience = this.getStateNew(attributesAfter.technologies.internetInfrastructureResilience.internetInfrastructureResilience);
    attributesState.technologies.softwareQuality.softwareQuality = this.getStateNew(attributesAfter.technologies.softwareQuality.softwareQuality);

    attributesState.technologies.technicalSecurityControls.technicalSecurityControls = this.getStateNew(attributesAfter.technologies.technicalSecurityControls.technicalSecurityControls);

    attributesState.technologies.cryptographicControls.cryptographicControls = this.getStateNew(attributesAfter.technologies.cryptographicControls.cryptographicControls);

    attributesState.technologies.cyberSecurityMarketplace.cyberSecurityTechnologies = this.getStateNew(attributesAfter.technologies.cyberSecurityMarketplace.cyberSecurityTechnologies);
    attributesState.technologies.cyberSecurityMarketplace.cybercrimeInsurance = this.getStateNew(attributesAfter.technologies.cyberSecurityMarketplace.cybercrimeInsurance);


    attributesState.technologies.responsibleDisclosure.responsibleDisclosure = this.getStateNew(attributesAfter.technologies.responsibleDisclosure.responsibleDisclosure);


    return attributesState;
  }

  getState(valueBefore, valueAfter) {



    if (valueBefore != '' && valueAfter != '') {
      if (valueBefore == valueAfter) {
        return 'none';
      } else {
        this.thereAreChanges = true;
        return 'table-warning';
      }

    } else {
      if (valueAfter != '') {
        this.thereAreChanges = true;
        return 'table-warning';
      } else {
        this.isAvailableLoad = false;
        this.isDataComplete = false;
        return 'table-danger';
      }
      /*
      if (valueBefore !='') {
        return 'none';
      }
      */
    }
  }

  getStateNew(value) {
    if (value == '') {
      this.isAvailableLoad = false;
      this.isDataComplete = false;
      return 'table-danger';
    }
    return 'none';
  }

  // Funcion para cargar la información presentada
  loadInfo() {
    for (let country of this.data['carga']) {
      //console.log(country);
      this._countryService.addCountry(country).subscribe(
        res => {
          //console.log(res);
        }, err => {
          //console.log(err);
        }
      )
    }
    
    
    Swal.fire({
      title: 'Cargue exitoso!',
      text: 'Se cargo la información de los paises correctamente.',
      type: 'success',
      confirmButtonText: 'OK'
    });
     
    
    this.cleanQueue();
    
  }

}
