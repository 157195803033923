import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm;
  identity;
  valuesPasswordDiferent:boolean=false;
  

  constructor(
    private formBuilder:FormBuilder,
    private _userService: UserService
  ) {
    
   }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      currentPasssword: ['',Validators.required],
      newPassword: ['',Validators.required],
      confirmationNewPassword: ['',Validators.required]
    })
  }

  validatePasswords(){
    if(this.changePasswordForm.value.newPassword == this.changePasswordForm.value.confirmationNewPassword) {
      this.valuesPasswordDiferent = false;
    } else {
      this.valuesPasswordDiferent = true;
    }
  }

  onSubmit(changePasswordForm){
    
    this._userService.changePassword(changePasswordForm)
    .subscribe(
      response => {
        Swal.fire({
          title: 'Actualización exitosa!',
          text: 'Se actualizó la contraseña correctamente.',
          type: 'success',
          confirmButtonText: 'OK'
        });
        this.resetForm();
      }, err => {
        Swal.fire({
          title: 'Actualización fallida!',
          text: 'No se pudo actualizar la contraseña. Por favor corrobora tu contraseña actual',
          type: 'error',
          confirmButtonText: 'OK'
        });
      }
    )
  }

  resetForm(){
    this.ngOnInit()
    this.validatePasswords()
  }
}