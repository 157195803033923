import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators'

const HEADERS = new HttpHeaders({
    'Content-Type': 'application/json',
})


@Injectable()
export class CountryService {

    public url: string;
    public identity;
    public token;

    constructor(
        private http: HttpClient
    ) {
        this.url = GLOBAL.url + '/countries';
    }

    getAllCountries() {
        return this.http.get(this.url, { headers: HEADERS })
            .pipe(map(res => {
                return res['country'];
            }));
    }

    getCountriesByYear(year) {
        return this.http.get(this.url + '?year=' + year, { headers: HEADERS })
            .pipe(map(res => {
                return res['country'];
            }));
    }

    getCountryById(id) {
        return this.http.get(this.url + '?id=' + id, { headers: HEADERS })
            .pipe(map(res => {
                return res['country'];
            }));
    }

    getCountryByIsoAndYear(iso, year){
        return this.http.get(`${this.url}?code=${iso}&year=${year}`, { headers: HEADERS })
            .pipe(map(res => {
                return res['country'];
            }));
    }

    addCountry(country) {
        let token = this.getToken();
        const HEADERS = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': token
        })
        let params = JSON.stringify(country);
        return this.http.post(this.url, params ,{ headers: HEADERS })
            .pipe(map(res => {
                return res;
            }));
    }

    delteCountry(iso, year) {
      let token = this.getToken();
      const HEADERS = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
      console.log(`${this.url}?iso=${iso}&year=${year}`)
      return this.http.delete(`${this.url}?iso=${iso}&year=${year}`, { headers: HEADERS })
          .pipe(map(res => {
              return res['country'];
          }));
    }

    // Funcion para obtener el token del localstorage
    getToken() {
      let token = JSON.parse(localStorage.getItem('token'));
      this.token = null;
      if (token != "undefined") {
        this.token = token;
      }
      return this.token;
    }
}
