// Modulos
import { Routes, RouterModule } from '@angular/router';

//Componentes
import { LoginComponent } from './components/login/login.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { CountryUploadComponent } from './components/country-upload/country-upload.component';
import { ManageCountryMaturityLevels } from './components/manage-country-maturity-levels/manage-country-maturity-levels.component';
import { ManageCountryInformationComponent } from './components/manage-country-information/manage-country-information.component';
import { ManageTabAboutComponent } from './components/manage-tab-about/manage-tab-about.component';
import { ManageTabContributorsComponent } from './components/manage-tab-contributors/manage-tab-contributors.component';
import { ManageTabCreditsComponent } from './components/manage-tab-credits/manage-tab-credits.component';
import { ManageTabInformationInstComponent } from './components/manage-tab-information-inst/manage-tab-information-inst.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/home/home.component';
import { AuditComponent } from './components/audit/audit.component'
import { ChangePasswordComponent } from './components/change-password/change-password.component'


const APP_ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '', redirectTo: 'login', pathMatch:'full' },
    { path: '**', pathMatch: 'full', redirectTo: 'main' },
    { path: 'admin-panel', component: MainComponent,   children: [
        { path: '', redirectTo: 'home', pathMatch:'full' },
        { path: 'home', component: HomeComponent },
        { path: 'auditoria', component: AuditComponent },
        { path: 'registrar', component: UserAddComponent },
        { path: 'gestionar-usuario', component: ManageUserComponent },
        { path: 'gestionar-informacion-inst', component: ManageTabInformationInstComponent },
        { path: 'gestionar-creditos', component: ManageTabCreditsComponent },
        { path: 'gestionar-contribuidores', component: ManageTabContributorsComponent },
        { path: 'cargar-archivo', component: CountryUploadComponent },
        { path: 'gestionar-maturity-levels', component: ManageCountryMaturityLevels },
        { path: 'gestionar-acerca-de', component: ManageTabAboutComponent },
        { path: 'gestionar-informacion-pais', component: ManageCountryInformationComponent },
        { path: 'change-password', component: ChangePasswordComponent },
      ]
    }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);