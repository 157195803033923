import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { map } from 'rxjs/operators'

@Injectable()
export class AuditService {

  url: string

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {
    this.url = GLOBAL.url + '/audits';
  }

  getAudit(paramsAudit) {
    let token = this.tokenService.getToken();
    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    })
    let params = JSON.stringify(paramsAudit);
    console.log('AuditService Params:',params)
    return this.http.post(this.url, params, { headers: HEADERS })
      .pipe(map(res => {
        return res;
      }));
  }

}
