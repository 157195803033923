// Modulos
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FileUploadModule } from 'ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ManageCountryMaturityLevels } from './components/manage-country-maturity-levels/manage-country-maturity-levels.component';
import { CountryUploadComponent } from './components/country-upload/country-upload.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { ManageCountryInformationComponent } from './components/manage-country-information/manage-country-information.component';
import { ManageTabAboutComponent } from './components/manage-tab-about/manage-tab-about.component';
import { ManageTabContributorsComponent } from './components/manage-tab-contributors/manage-tab-contributors.component';
import { ManageTabCreditsComponent } from './components/manage-tab-credits/manage-tab-credits.component';
import { ManageTabInformationInstComponent } from './components/manage-tab-information-inst/manage-tab-information-inst.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
// Routes
import {APP_ROUTING} from './app.routing';

import { TRANSLATION_PROVIDERS } from './translations/translation';
// Pipes
import {TranslatePipe } from './pipes/translatePipe';

// Services
import { UserService } from './services/user.service';
import { TokenService } from './services/token.service';
import { MainComponent } from './components/main/main.component';
import { CountryService } from './services/country.service';
import { CountryInfoService } from './services/countryInfo.service';
import { CybersecurityContentService } from './services/cybersecurityContent.service';
import { CybersecurityInstitucionalService } from './services/cybersecurityInstitucionalInfo.service';
import { CybersecurityCreditService } from './services/cyberSecurityCredit.service';
import { CybersecurityContributorService } from './services/cyberSecurityContributor.service';
import { TranslateService } from './services/translateService';
import { HomeComponent } from './components/home/home.component';
import { AuditComponent } from './components/audit/audit.component';
import { AuditService } from './services/audit.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

@NgModule({
  declarations: [
    TranslatePipe,
    AppComponent,
    LoginComponent,
    ManageCountryInformationComponent,
    ManageCountryMaturityLevels,
    CountryUploadComponent,
    UserAddComponent,
    MainComponent,
    ManageTabAboutComponent,
    ManageTabContributorsComponent,
    ManageTabCreditsComponent,
    ManageTabInformationInstComponent,
    ManageUserComponent,
    HomeComponent,
    AuditComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    APP_ROUTING,
    HttpClientModule,
    FileUploadModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      progressBar: true,
      closeButton: true
    })
  ],
  providers: [
    UserService,
    TokenService,
    CountryService,
    CountryInfoService,
    CybersecurityContentService,
    CybersecurityInstitucionalService,
    CybersecurityCreditService,
    CybersecurityContributorService,
    TranslateService,
    AuditService,
    TRANSLATION_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
