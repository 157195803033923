export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    'salir': 'Salir',
    'modulo_administrativo':'Módulo administrativo del Observatorio de Ciberseguridad',
    'administrar_contenido':'Administrar contenido',
    'administrar_usuario':'Administrar usuarios',
    'crear_nuevo_usuario': 'Crear usuario',
    'gestionar_usuarios': 'Gestionar usuarios',
    'auditoria': 'Auditoria',
    'cargar_niv_madurez': 'Cargar niveles de madurez',
    'gestionar_niv_madurez': 'Gestionar niveles de madurez',
    'ges_acerca_de': 'Gestionar Acerca de',
    'ges_creditos': 'Gestionar Creditos',
    'ges_contribuidores': 'Gestionar Contribuidores',
    'ges_info_instituci': 'Gestionar Información institucional',
    'ges_info_general_paises': 'Gestionar Información general de paises',

    'nombres':'Nombres',
    'nombre':'Nombre',
    'nombres_obligatorios':'Los nombres son obligatorio',
    'apellidos':'Apellidos',
    'apellidos_obligatorios':'Los apellidos son obligatorios',
    'correo':'Correo electrónico',
    'correo_obligatorio':'El correo electrónico es obligatorio',
    'contrasena':'Contraseña',
    'contrasena_obligatorio':'La contraseña obligatoria',
    'buscar_por_nombre':'Buscar por nombre',
    'estado':'Estado',
    'acciones':'Acciones',
    'deshabilitar':'Deshabilitar',
    'habilitar':'Habilitar',
    'eliminar':'Eliminar',

    'pasos':'Pasos',
    'seleccionar_archivo':'1. Seleccionar el archivo a Cargar.',
    'cargar_archivo':'2. Cargar el archivo.',
    'verificar_info':'3. Verificar la información cargada.',
    'confirmar_actualizacion':'4. Confirmar actualización.',
    'buscar_archivo':'Seleccionar archivo',
    'cargar': 'Cargar',
    'confirmar': 'Confirmar',
    'limpiar': 'Limpiar',

    'pais_nuevo':'País nuevo',
    'pais_existente':'País existente',
    'existen_cambios':'Existen cambios',
    'informacion_incompleta':'Información incompleta',
    'atributo':'Atributo',
    'valor_existente':'Valor existente',
    'valor_nuevo':'Valor nuevo',
    'valor':'Valor',

    'anio':'Año',
    'detalle':'Detalle',
    'confirmo_eliminacion_niveles':'Confirmo la eliminaciòn de los niveles de madurez de ',
    'confirmo_eliminacion':'Confirmo la eliminaciòn',
    'cerrar':'Cerrar',
    'guardar':'Guardar',
    'cancelar':'Cancelar',
    'resumen_ejecutivo':'Resumen ejecutivo',
    'descripcion':'Descripción',
    'contribuidores':'Contribuidores',
    'informacion_institucional':'Información institucional',
    'editar':'Editar',
    'poblacionTotalDelPais':'Población total del país',
    'personasAccesoInternet':'Con acceso a Internet',
    'personasAccesoCelular':'Con teléfono móvil',
    'penetracionInternet':'Penetración a Internet',

    'user': 'Usuario',
    'names': 'Nombres',
    'surnames': 'Apellidos',
    'date': 'Fecha',
    'country': 'País' ,
    'year' : 'Año',
    'data': 'Petición'
  



};
